import React, {Component, Ref} from 'react'
import './Features.scss'
import group1 from '../../../assets/images/grup1.svg'
import productivity from '../../../assets/images/productivity.svg'
import networking from '../../../assets/images/networking.svg'
import drone from '../../../assets/images/drone.svg'
import illustration from '../../../assets/images/illustration.svg'
import {Features} from "../../models/features";

interface PropsType {
    align: string
}

export default class FeatureSection extends React.Component<PropsType> {
    featuresList: Features[];

    constructor(props: any) {
        super(props);
        this.featuresList = [
            new Features({
                imagePath: group1,
                title: 'Group multiple orders in the same ride ',
                subTitle: 'Our software enables couriers to serve \n multiple vendors and clients in the same ride.'
            }),
            new Features({
                imagePath: productivity,
                title: 'Optimized pick-up and drop-offs',
                subTitle: 'We will change the cost structure of your\n deliveries by continuous optimization.'
            }),
            new Features({
                imagePath: networking,
                title: 'Optimized enrollment',
                subTitle: 'Our efficient UI streamlines the \n enrollment process of your couriers.'
            }),
            new Features({
                imagePath: drone,
                title: 'Fleet management',
                subTitle: 'Our asset management tool will track your \n vehicles and ensure their compliance.'
            }),
            new Features({
                imagePath: illustration,
                title: 'AI-powered demand anticipation and decision making',
                subTitle: 'Cutting edge technology will \n decrease your cost per order.'
            }),
        ]
    }

    render() {
        return (
            <React.Fragment>

                <div className="features section">
                    <span className='section-title'>How can Rapidonkey grow your business? </span>
                    <p className='section-text'>Have you thought about starting to deliver locally and you don’t know
                        how to start?</p>
                    <p className='section-text'> Are you afraid that additional costs would cut into your margins? </p>
                    <p className='section-text weight-medium'>Stop worrying and check out Rapidonkey.</p>

                    <div className='features-list'>
                        {this.featuresList && this.featuresList.map((el: Features, index) =>
                            <div className='feature' key={index}>
                                <img alt='feature' src={el.imagePath}/>
                                <div className="right-side-text">
                                    <span className="feature-title">{el.title}</span>
                                    <span className="feature-subtitle">{el.subTitle}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

import './Container.scss'
import React from "react";
import {Article} from "../../models/article";
import ArticleCard from "./card/ArticleCard";
import {Link} from 'react-router-dom'

type ContainerProps = {
    articles: Article[]
}

function ArticlesContainer(props: ContainerProps) {
    return (
        <div className={"articles-container"}>
            {props.articles && props.articles.map((el: Article) => <Link to={`/articles/${el.id}`}
                                                                         key={el.id}><ArticleCard
                id={el.id} image={el.image}
                // category={el.category}
                title={el.title}/></Link>)}
        </div>
    )
}

export default ArticlesContainer;
export class Features {
    imagePath: string;
    title: string;
    subTitle: string;

    constructor(data: any) {
        this.imagePath = data?.imagePath
        this.title = data?.title
        this.subTitle = data?.subTitle
    }

}

export class Section {
    imagePath: string;
    title: string;
    subTitle: string[];
    imageAlign: string;

    constructor(data: any) {
        this.imagePath = data?.imagePath
        this.title = data?.title
        this.subTitle = data?.subTitle ? data.subTitle.split('\n') : undefined
        this.imageAlign = data?.imageAlign
    }
}

import React from 'react'
import './Carousel.scss'
import {CarouselModel} from "../../models/carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel as ReactResponsiveCarousel} from 'react-responsive-carousel';

interface CarouselProps {
    carouselItems?: CarouselModel[]
}

interface CarouselState {
    length: any
    carouselItems: any
    blur: boolean
    isCarouselActive: boolean
    currentSlide: number
}

export default class Carousel extends React.Component<CarouselProps, CarouselState> {
    interval: any
    carouselSettings: any
    indicatorLeft: any
    frameRef: any

    constructor(props: any) {
        super(props);

        this.state = {
            length: props.carouselItems.length,
            carouselItems: [],
            blur: false,
            isCarouselActive: true,
            currentSlide: 0
        }
        this.carouselSettings = {
            infiniteLoop: true,
            showArrows: false,
            showThumbs: false,
            showStatus: false,
            showIndicators: false,
            stopOnHover: false
        }

        this.indicatorLeft = 0
        this.frameRef = React.createRef()

        this.listenScrollEvent = this.listenScrollEvent.bind(this)
    }

    componentDidMount() {
        this.autoSlideChange()
        window.addEventListener('scroll', this.listenScrollEvent)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenScrollEvent)
        clearInterval(this.interval)
    }

    nextSlide() {
        this.setState((state) => ({
            currentSlide: state.currentSlide < this.state.length - 1 ? state.currentSlide + 1 : 0,
        }))
    }

    nextIndicator() {
        if (this.state.currentSlide < this.state.length - 1) {
            this.indicatorLeft = 25 * (this.state.currentSlide + 1)
        } else {
            this.indicatorLeft = 0
        }
    }

    updateCurrentSlide(index: number) {
        if (this.state.currentSlide !== index) {
            this.setState({
                currentSlide: index,
            })
        }
    }

    onSwitch() {
        this.setState({blur: true})
        setTimeout(
            () => {
                this.setState({blur: false})
            }, 600
        )
    }

    carouselSlide(index: any) {
        clearInterval(this.interval)
        this.indicatorLeft = (25 * index)
        this.onSwitch()
        this.updateCurrentSlide(index)
        this.autoSlideChange()
    }

    listenScrollEvent() {
        let position = this.frameRef.current.getBoundingClientRect()
        if (position.top >= 0 && position.bottom <= window.innerHeight) {
            this.setState({isCarouselActive: true})
        } else if (position.top < window.innerHeight && position.bottom >= 0) {
            this.setState({isCarouselActive: false})
        }
    }

    autoSlideChange() {
        this.interval = setInterval(() => {
            if (this.state.isCarouselActive) {
                this.nextIndicator()
                this.onSwitch()
                this.nextSlide()
            }
        }, 4000)
    }

    render() {
        const activeIndex = this.state.currentSlide
        return (
            <React.Fragment>
                <div className='carousel'>
                    <div className='carousel-container'>
                        <div className='carousel-frame' ref={this.frameRef}>
                            <section id='carousel-slider'>
                                <ReactResponsiveCarousel {...this.carouselSettings}
                                                         selectedItem={this.state.currentSlide}>
                                    {this.props.carouselItems && this.props.carouselItems.map((item: CarouselModel, index: number) =>
                                        <div
                                            className={`slide ${item.index}`}
                                            key={index}>
                                            {item.imagePath ? (
                                                <img
                                                    srcSet={`${item.imagePath} 1x, ${item.imagePath2} 2x`}
                                                    sizes="1x, (min-width:2500px) 2x"
                                                    alt="Carousel image"
                                                    src={item.imagePath}/>
                                            ) : (
                                                item.component
                                            )}
                                        </div>
                                    )}
                                </ReactResponsiveCarousel>
                            </section>
                            <div className="buttons-container">
                                <div className="gray-buttons-container">
                                    <div className={this.state.blur ? `walking clicked ` : ` walking`}
                                         style={{left: `${this.indicatorLeft}` + '%'}}/>
                                    {this.props.carouselItems && this.props.carouselItems.map((item: CarouselModel, index: number) =>
                                        <div key={index}
                                             className={item.index === activeIndex ? `circle ${item.index} active ` : `circle ${item.index}`}
                                             onClick={() => {
                                                 this.carouselSlide(item.index)
                                             }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

import React from 'react';
import { Link} from "react-router-dom";
import linkedin from '../../../assets/images/linkedin.svg'
import './Footer.scss'
import {linkedinURL} from "../../../urls";


export default class Footer extends React.Component {
	render() {
		return (
			<React.Fragment>
				<div className='footer'>
					<div className='info-pages'>
						<Link to='/terms'> Terms & Conditions </Link>
						<Link to='/privacy'> Privacy Policy </Link>
					</div>
					<div className='footer-info'>
						<div>Rapidonkey © {new Date().getFullYear()} - All rights reserved.</div>
						<a href={linkedinURL}>
							<img src={linkedin} alt='linkedin-logo'/>
						</a>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

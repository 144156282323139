import React from 'react';
import './OurAppsSection.scss'
import orders from '../../../assets/images/Orders.png';
import tracking from '../../../assets/images/Realtime tracking.png'
import shifts from '../../../assets/images/Shifts.png'
import onDemand from '../../../assets/images/On demand order.jpg'
import arrival from '../../../assets/images/Arrival at delivery.jpg'
import {CarouselModel} from "../../models/carousel";
import Carousel from "../carousel-component/Carousel";

export default class OurAppsSection extends React.Component {
    carouselItems?: CarouselModel[]

    constructor(props: any) {
        super(props);

        this.carouselItems = [
            new CarouselModel({
                index: 0,
                imagePath: orders,
                imagePath2: orders,
            }),
            new CarouselModel({
                index: 1,
                imagePath: tracking,
                imagePath2: tracking,
            }),

            new CarouselModel({
                index: 2,
                imagePath: shifts,
                imagePath2: shifts,
            }),
            new CarouselModel({
                index: 3,
                imagePath: onDemand,
                imagePath2: onDemand,
            }),
            new CarouselModel({
                index: 4,
                imagePath: arrival,
                imagePath2: arrival,
            }),
        ]
    }

    render() {
        return (
            <React.Fragment>
                <div className={"our-apps section"}>
                    <span className='our-apps-title section-title'>Our Apps</span>
                    <Carousel carouselItems={this.carouselItems}/>
                </div>
            </React.Fragment>
        )
    }
}

import React from 'react';
import './ImageSection.scss'
import {Section} from '../../models/section'
import order from '../../../assets/images/ordergrouping.svg';
import pickups from '../../../assets/images/pickups.svg';
import enroll from '../../../assets/images/enroll.svg';
import fleet from '../../../assets/images/fleet.svg';
import anticipation from '../../../assets/images/anticipation.svg'

export default class ImageSection extends React.Component {
    sectionsList: Section[];

    constructor(props: any) {
        super(props);
        this.sectionsList = [
            new Section({
                imagePath: order,
                title: 'Order grouping',
                subTitle: 'Our cutting-edge demand anticipation and our algorithm for route optimization deliver more orders per hour, for higher profits.',
                imageAlign: 'left'
            }),
            new Section({
                imagePath: pickups,
                title: 'Optimized pick-ups and drop-offs',
                subTitle: 'Pick-up and drop-off delays reduce significantly the efficiency. \n AI-powered orchestration of all actors will allow couriers to increase deliveries from 2 orders/h to 5 orders/h.',
                imageAlign: 'right'
            }),
            new Section({
                imagePath: enroll,
                title: 'Optimized enrollment & shift management',
                subTitle: 'Flexible shifts and demand anticipation improve efficiency. \n A transparent scoring mechanism identifies who’s the best fit for unassigned tasks. \n Real-time tracking of service and data-driven scheduling provide a competitive edge.',
                imageAlign: 'left'
            }),
            new Section({
                imagePath: fleet,
                title: 'Fleet and equipment management',
                subTitle: 'Smart asset allocation to logistic hubs increases asset utilization and reduces maintenance costs. \n RPA-powered documents management monitors compliance for each asset. \n Connected objects sustain productivity improvement.',
                imageAlign: 'right'
            }),
            new Section({
                imagePath: anticipation,
                title: 'Demand anticipation',
                subTitle: 'AI-powered demand anticipation allows businesses to schedule shifts efficiently. \n Days of having couriers on the job with no assignment are gone with our AI algorithm. \n Now you can plan your shifts efficiently based on time of day, traffic, weather and sales campaigns.',
                imageAlign: 'left'
            }),

        ]
    }

    render() {
        return (
            <React.Fragment>
                <div className='image-sections section'>
                    {this.sectionsList && this.sectionsList.map((el: Section, index) => {
                        return (
                            <div className={`section-container ${el.imageAlign}`} key={index}>
                                <img src={el.imagePath}/>
                                <div className='text-container'>
                                    <span className='section-title'>{el.title}</span>
                                    {el.subTitle && el.subTitle.map((sub: string, index) => (
                                        <p className='section-text' key={index}>{sub}</p>))}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </React.Fragment>
        )
    }

}

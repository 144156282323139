import React, {useState} from 'react';
import classNames from 'classnames'
import './Input.scss'
import {TextField} from "@material-ui/core";

type InputProps = {
    label: string,
    required: boolean,
    helperText?: string,
    error?: boolean,
    className?: string,
    onChange?: any,
    onFocus?: any,
    onBlur?: any,
    value?: any,
    name?: any,
    type?: any,
    multiline?: any,
    rowsMax?: any,
    maxLength?: number,
    showCharCounter?: boolean,
}

function Input(props: InputProps) {
    const [isFocused, setIsFocused]: [boolean, any] = useState(false);
    const [nbChars, setNbChars]: [number, any] = useState(0);

    const onChange = (event: any) => {
        setNbChars(event.target.value.length)
        props.onChange(event, props.name)
    }
    const onBlur = (event: any) => {
        setIsFocused(false)
        props.onBlur(event, props.name)
    }

    return (
        <>
            <TextField
                className={props.className}
                label={props.label}
                required={props.required}
                helperText={props.helperText}
                onChange={onChange}
                onFocus={() => setIsFocused(true)}
                onBlur={onBlur}
                type={props.type}
                multiline={props.multiline}
                maxRows={props.rowsMax}
                inputProps={{maxLength: props.maxLength}}
            />
            {props.showCharCounter &&
                <div className={classNames({'charCounter': true, 'charCounterActive': isFocused})}>
                    {nbChars}/300
                </div>
            }
        </>
    )
}

Input.defaultProps = {
    maxLength: 48000,
    showCharCounter: false
}

export default Input
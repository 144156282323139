import React from 'react'
import "./JoinUs.scss"
import Header from "../../components/header-component/Header";
import Footer from "../../components/footer-component/Footer";
import {History} from "history";
import Positions from "../../context/Positions";

import {Link} from "react-router-dom";
import loader from '../../../assets/images/Rapidonkey_Loader.gif'
import chevron from '../../../assets/images/chevron-left.svg'

interface Props {
    history: History,
    location: Location,
}

interface State {
    positions: any,
    loading: boolean,
}

export default class JoinUs extends React.Component<Props, State> {
    public positions: any

    constructor(props: any) {
        super(props);
        this.state = {
            positions: null,
            loading: true,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('popstate', (event) => {
            this.props.history.push('/')
        }, false);
    }

    render() {
        return (
            <React.Fragment>
                <Header areButtonsAvailable={true} activeLink={"careers"}/>
                <div className='join section'>
				<span className='section-title big-size '>
					Join us
				</span>
                    <span className='section-text'>Here are the open positions. <br/> Check them out and see what suits you</span>
                    <hr/>
                    <Positions/>
                </div>
                <Footer/>
            </React.Fragment>
        )
    }
}

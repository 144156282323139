import React from 'react'
import './PricingCard.scss'
import check from '../../../../assets/images/check.svg'

type CardProps = {
    title: string;
    subTitle: string;
    image: string;
    price: number;
    features: [string];
}

function PricingCard(props: CardProps) {
    return (
        <>
            <div className={"card-shape"}>
                <div className={"card-main"}>
                    <div className={"card-main-text"}>
                        <span className={"card-title"}>{props.title}</span>
                        <p className={"card-description"}>{props.subTitle}</p>
                    </div>
                    <img src={props.image} alt={"feature"}/>
                </div>
                <div className={"card-info"}>
                    <div className={"card-price"}>
                        <p className={"price-value"}>{parseFloat(String(props.price)).toFixed(2)} €</p>
                        <p className={"per-order"}>per order</p>
                    </div>
                    <div className={"card-features"}>
                        {props.features && props.features.map((text: string, index) =>
                            <div className={"card-feature"} key={index}>
                                <p>{text}</p>
                                <img className={"check-img"} src={check} alt={"feature"}/>
                            </div>)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PricingCard;
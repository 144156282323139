import React from 'react';
import {Route, BrowserRouter as Router} from "react-router-dom";
import './App.scss';
import HomePage from "./pages/home/HomePage";
import Terms from "./pages/terms-and-conditions/Terms";
import Privacy from "./pages/privacy-policy/Privacy";
import Contact from "./pages/contact/ContactPage";
import ThankYou from "./pages/thank-you/ThankYou";
import JoinUs from "./pages/join-us/JoinUs";
import JobDescription from "./pages/job-description/JobDescription";
import {APIContextProvider} from "./context/apiContext";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Articles from "./pages/articles/Articles";
import Article from "./pages/article/Article";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
});
export default class App extends React.Component<any, any> {

    render() {
        return (
            <div className="App">
                <APIContextProvider>
                    <Router>
                        <Route exact path='/' component={HomePage} />
                        <Route exact path='/terms' component={Terms} />
                        <Route exact path='/privacy' component={Privacy} />
                        <Route exact path='/join-us' component={JoinUs} />
                        <Route exact path='/articles' component={Articles} />
                        <Route exact path='/contact' component={Contact} />
                        <Route exact path='/thank-you' component={ThankYou} />
                        <Route exact path='/job/:id' component={JobDescription} />
                        <Route exact path='/articles/:id' component={Article} />
                    </Router>
                </APIContextProvider>
            </div>
        );
    }
}


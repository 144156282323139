export class JoinFields {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	resume: any;
	coverLetter: any;
	startDate: string;
	foundUs: string;
	linkedin: string;
	website: string;
	links: [];
	agreedTerms:boolean;
	changeOfView: string;
	careerObjectives: string;


	constructor(data: any) {
		this.firstName = data?.firstName
		this.lastName = data?.lastName
		this.email = data?.email
		this.phone = data?.phone
		this.resume = data?.resume
		this.coverLetter = data?.coverLetter
		this.startDate = data?.startDate
		this.foundUs = data?.foundUs
		this.linkedin = data?.linkedin
		this.website = data?.website
		this.links = data?.links ? data.links : ['']
		this.agreedTerms = data?.agreedTerms
		this.changeOfView = data?.changeOfView
		this.careerObjectives = data?.careerObjectives
	}
}

export class CarouselModel {
    imagePath: string
    imagePath2: string
    component?: any
    index: number

    constructor(data:any) {
        this.imagePath = data?.imagePath;
        this.imagePath2 = data?.imagePath2;
        this.index = data?.index;
        this.component = data?.component;
    }
}

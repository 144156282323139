import React from 'react';
import Footer from "../../components/footer-component/Footer";
import Header from "../../components/header-component/Header";
import {History} from 'history'
import './ThankYou.scss'
import {Link} from 'react-router-dom'
import check from '../../../assets/images/check.svg'
import chevron from '../../../assets/images/chevron-left.svg'

interface AppProps {
    history: History
}

export default class ThankYou extends React.Component<AppProps> {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                <Header areButtonsAvailable={true}/>
                <div className='thank-you section'>
                    <span className='section-title title-size'> Contact us </span>
                    <img className='check-img' src={check}/>
                    <span className='section-text'> Thank you for reaching out to us. <br/>
			Our sales representative will contact you in the shortest time.
			</span>
                    <Link to='/'>
					<span className='redirect'>
						<img src={chevron}/>
						Go to home page
					</span>
                    </Link>
                </div>
                <Footer/>
            </React.Fragment>
        )
    }
}

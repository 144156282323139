import React from 'react'
import aboutus from '../../../assets/images/Illustration-about.svg'
import './AboutUs.scss'
interface PropsType {
    align: string
}

export default class AboutUs extends React.Component<PropsType> {

    render() {
        return (
            <React.Fragment>
                <div className='about-us section'>
                    <span className='section-title'>About us </span>
                    <p className='section-text weight-bold'>We are a team of technology enthusiasts with the mission of
                        optimizing local deliveries.</p>
                    <p className='section-text'>As a group of young people working in an office building in an urban
                        area,
                        we order lunch every day. <br/> This is how we noticed how inefficient food delivery
                        is. <br/> Late
                        deliveries, missing items, order mismatch, lack of predictability are all happening every week.
                    </p>
                    <p className='section-text'>After analyzing the problem, getting feedback from delivery experts, we
                        launched <span className='weight-bold'>Rapidonkey</span> with the aim of bringing reliability, predictability and efficiency to local deliveries. </p>
                    <img alt='about-us-image' src={aboutus}/>
                </div>
            </React.Fragment>
        )
    }
}

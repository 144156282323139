import React from 'react'
import Input from "../../components/input-component/Input";
import './ContactPage.scss'
import Header from "../../components/header-component/Header";
import Footer from '../../components/footer-component/Footer';
import {Contact} from "../../models/contact";
import * as emailJs from 'emailjs-com'
import axios from 'axios'
import {Checkbox} from "@material-ui/core";
import {privacyURL, termsURL} from "../../../urls";

export default class ContactPage extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            error: new Contact({}),
            values: new Contact({}),
            agreedTerms: true,
        }
        this.onChange = this.onChange.bind(this)
        this.sendMessage = this.sendMessage.bind(this);
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('popstate', (event) => {
            this.props.history.push('/')
        }, false);
        try {
            const response = await fetch(`${process.env.REACT_APP_RAPID_DONKEY_WEBSITE_API}/mail`);
            const json = await response.json();
            this.setState({mailData: json.mailData[0]});
        } catch (error) {
            console.log(error);
        }
    }

    onChange(e: any, name: string) {
        let newContactObj = {...this.state.values}
        newContactObj[name] = e.target.value
        let errors = this.validateForm(newContactObj);
        this.setState((prevState: any) => {
            return {values: newContactObj, error: {...prevState.error, [name]: errors.error[name]}}
        })
    }

    validateForm = (receivedObj = null) => {
        let objToBeVerified = receivedObj ? receivedObj : {...this.state.values}
        let errorObj = {...this.state.error}

        for (const [name, value] of Object.entries(objToBeVerified)) {
            if (!["agreedTerms"].includes(name)) {
                if (!value) {
                    errorObj[name] = 'This field is required'
                } else {
                    errorObj[name] = ''
                }
                switch (name) {
                    case 'phone':
                        let phoneReg = /^\d+$/
                        if (!phoneReg.test(value as string) && value)
                            errorObj[name] = 'Please enter a valid phone number'
                        break
                    case 'email':
                        let emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        if (!emailReg.test(value as string) && value)
                            errorObj[name] = 'Please enter a valid email address'
                        break;
                }
            }
        }
        let hasError = false;
        for (const [name, value] of Object.entries(objToBeVerified)) {
            if (!["agreedTerms"].includes(name) && errorObj[name] !== "") {
                hasError = true
            }
        }
        return {error: errorObj, hasError: hasError}
    }

    validateData = (e: any, name: string) => {
        let errors = this.validateForm();
        this.setState((prevState: any) => {
            return {
                error: {...prevState.error, [name]: errors.error[name]}
            }
        })
    }

    sendMessage(e: any) {

        e.preventDefault();
        let errors = this.validateForm();

        if (errors.hasError) {
            this.setState({error: errors.error})
            console.log('error')
        } else {
            e.preventDefault();
            const templateParams = {
                from_name: this.state.values.lastName + ' ' + this.state.values.firstName + " (" + this.state.values.email + ")",
                name: this.state.values.lastName + ' ' + this.state.values.firstName,
                email: this.state.values.email,
                phone: this.state.values.phone,
                company: this.state.values.companyName,
                website: this.state.values.companyWebsite,
                deliveries: this.state.values.deliveries,
                agreedTerms: this.state.agreedTerms
            }
            emailJs
                .send(this.state.mailData.service_id, this.state.mailData.template_id, templateParams, this.state.mailData.user_id)
                .then((response) => {
                        console.log('')
                    }, (err) => {
                        console.log('Something went wrong', err)
                    }
                );
            this.props.history.push('/thank-you')
            let formData = new FormData()
            formData.append('firstName', this.state.values.firstName);
            formData.append('lastName', this.state.values.lastName);
            formData.append('email', this.state.values.email);
            formData.append('phone', this.state.values.phone);
            formData.append('companyName', this.state.values.companyName);
            formData.append('deliveries', this.state.values.deliveries);
            formData.append('companyWebsite', this.state.values.companyWebsite);
            formData.append('agreedTerms', this.state.agreedTerms);
            axios.post(`${process.env.REACT_APP_RAPID_DONKEY_WEBSITE_API}/contact`, formData)
                .then(function (response: any) {
                    console.log('');
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    }

    render() {

        return (
            <React.Fragment>
                <Header areButtonsAvailable={true}/>
                <div className='contact form-div section'>
                    <span className='section-title big-size'> Contact us</span>
                    <span className='section-text'>Do you represent a business needing local deliveries? <br/>
					Fill in your
					information below and one of our colleagues will contact you to schedule a meeting to discuss a
					possible collaboration.</span>

                    <form className='contact-form form'>
                        <Input
                            className={`contact-input big-size-label ${!this.state.error.firstName ? 'valid' : 'has-error'}`}
                            label='First name'
                            name='firstName'
                            required={true}
                            error={this.state.error.firstName}
                            helperText={this.state.error.firstName}
                            value={this.state.firstName}
                            onChange={this.onChange}
                            onBlur={this.validateData}
                        />

                        <Input
                            className={`contact-input big-size-label ${!this.state.error.lastName ? 'valid' : 'has-error'}`}
                            label='Last name'
                            name='lastName'
                            required={true}
                            error={this.state.error.lastName}
                            helperText={this.state.error.lastName}
                            value={this.state.lastName}
                            onChange={this.onChange}
                            onBlur={this.validateData}
                        />
                        <Input className={`contact-input ${!this.state.error.email ? 'valid' : 'has-error'}`}
                               label='Email'
                               name='email'
                               required={true}
                               error={this.state.error.email}
                               helperText={this.state.error.email}
                               value={this.state.email}
                               onChange={this.onChange}
                               onBlur={this.validateData}
                        />
                        <Input className={`contact-input ${!this.state.error.phone ? 'valid' : 'has-error'}`}
                               label='Phone'
                               name='phone'
                               required={true}
                               error={this.state.error.phone}
                               helperText={this.state.error.phone}
                               value={this.state.phone}
                               onChange={this.onChange}
                               onBlur={this.validateData}
                        />
                        <Input className={`contact-input ${!this.state.error.companyName ? 'valid' : 'has-error'}`}
                               label='Company name'
                               name='companyName'
                               required={true}
                               error={this.state.error.companyName}
                               helperText={this.state.error.companyName}
                               value={this.state.companyName}
                               onChange={this.onChange}
                               onBlur={this.validateData}
                        />
                        <Input className={`contact-input ${!this.state.error.companyWebsite ? 'valid' : 'has-error'}`}
                               label='Company Website'
                               name='companyWebsite'
                               required={true}
                               error={this.state.error.companyWebsite}
                               helperText={this.state.error.companyWebsite}
                               value={this.state.companyWebsite}
                               onChange={this.onChange}
                               onBlur={this.validateData}
                        />
                        <Input className={`contact-input ${!this.state.error.deliveries ? 'valid' : 'has-error'}`}
                               label='How many daily deliveries does your company handle?'
                               name='deliveries'
                               required={true}
                               error={this.state.error.deliveries}
                               helperText={this.state.error.deliveries}
                               value={this.state.deliveries}
                               onChange={this.onChange}
                               onBlur={this.validateData}
                        />
                        <div className='checkbox-container'>
                            <Checkbox
                                defaultChecked={true}
                                value={this.state.agreedTerms}
                                disableRipple
                                onChange={() => {
                                    this.setState({agreedTerms: !this.state.agreedTerms})
                                }}
                            />
                            <label>I agree to the <a className='linkTo' href={termsURL}>Terms & Conditions</a> and <a
                                className='linkTo' href={privacyURL}>Privacy
                                Policy</a></label>
                        </div>
                    </form>
                    <div onClick={this.sendMessage}
                         className='button blue small default-hover'>send
                    </div>
                </div>
                <Footer/>
            </React.Fragment>
        )
    }
}

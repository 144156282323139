import React, {Component, Ref} from 'react'
import './IsRapidDonkeyForYou.scss'
import kitchen from '../../../assets/images/Kitchen.svg'
import grocery from '../../../assets/images/Grocery-01.svg'
import vendors from '../../../assets/images/Vendors.svg'
import {Card} from '../../models/cards'

export default class IsRapidDonkeyForYou extends React.Component {
    cardList: Card[];
    sectionRef: any

    constructor(props: any) {
        super(props);
        this.cardList = [
            new Card({
                imagePath: kitchen,
                text: '<p className="weight-light">We help <span className="weight-bold">restaurant and cloud kitchens</span> to manage their delivery personnel and their fleet.</p>'
            }),
            new Card({
                imagePath: grocery,
                text: ' <p className="weight-light"><span className="weight-bold">Local grocery stores</span> will conveniently send food to their customers.</p>'
            }),
            new Card({
                imagePath: vendors,
                text: ' <p className="weight-light">We enable all <span className="weight-bold">providers of essential goods</span> to reach new customers.</p>'

            })
        ]
        this.sectionRef = React.createRef();
    }

    render() {
        return (
            <React.Fragment>
                <div className="is-rapid-donkey-for-you section" ref={this.sectionRef}>
                    <span className='section-title'>Is Rapidonkey for you?</span>
                    <span className='section-text'> Whether you have 100 or 1000 daily orders, our solution helps you deliver efficiently</span>
                    <div className='cards-container'>
                        {this.cardList && this.cardList.map((el: Card, index) => {
                            return (
                                <div className='image-card' key={index}>
                                    <img src={el.imagePath}/>
                                    <div className='card-hover'>
                                        <div dangerouslySetInnerHTML={{__html: `${el.text}`}}/>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

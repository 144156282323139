import React from "react";
import {Link} from 'react-router-dom'
import {useAPI} from "../context/apiContext";
import loader from "../../assets/images/Rapidonkey_Loader.gif";
import chevron from '../../assets/images/chevron-left.svg'
export default function Positions() {
    const {positions} = useAPI();
    console.log(positions)
    return (
        <div className='positions-container'>
            { positions.length<1 ?
                <div className='loading-container'>
                    <img className='loader' src={loader}/>
                </div>
                :
                <React.Fragment>
                    {positions.map((el: any) =>
                        <Link to ={`/job/${parseInt(el.id)}`}>
                            <div className='position-card'>
                                <div>
                                    <span className='position-title'>{el.title}</span>
                                    <img src={chevron}/>
                                </div>
                                <span className='position-city'>{el.city}</span>
                            </div>
                        </Link>
                    )}
                </React.Fragment>
            }
        </div>
    );
}

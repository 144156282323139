import React from "react";
import './ArticleCard.scss'

type ArticleCardProps = {
    id: string,
    image: string,
    // category: string,
    title: string

}

function ArticleCard(props: ArticleCardProps) {
    return (
        <>
            <div className={"wrapper"}>
                <div className={"image-wrapper"}>
                    <img src={props.image}/>
                </div>
                {/*<div className={"category"}>{props.category}</div>*/}
                <div className={"title"}>{props.title}</div>
            </div>
        </>
    )
}

export default ArticleCard
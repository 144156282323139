import React from 'react';
import Header from 'app/components/header-component/Header';
import Loader from 'app/components/loader/Loader';
import fetchHTML from 'app/utils/fetchHTML'
import * as Sentry from "@sentry/react";
import DOMPurify from 'dompurify';

import './Privacy.scss'

export default () => {
    const [loading, setLoading] = React.useState(false);
    const [privacyPolicy, setPrivacyPolicy] = React.useState('');
    const [error, setError] = React.useState(false);

    React.useEffect(() => {
        setLoading(true)
        fetchHTML(`${process.env.REACT_APP_RAPIDONKEY_API_URL}/info/privacy`)
            .then((response) => {
                setPrivacyPolicy(response)
                setLoading(false)

            })
            .catch(reason => {
                setLoading(false)
                setError(true)
                Sentry.captureMessage(reason)
            })
    }, [])

    return (
        <React.Fragment>
            <Header areButtonsAvailable={true}/>
            {loading && <Loader/>}
            {(!loading && privacyPolicy) && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(privacyPolicy),
                    }}>
                </div>
            )}
            {error && <h1 className='section'>Something went wrong. Please refresh the page to try again</h1>}
        </React.Fragment>
    )
}

import React, {Component} from 'react';
import Header from "../../components/header-component/Header";
import IsRapidDonkeyForYou from "../../components/is-rapidonkey-section/IsRapidDonkeyForYou";
import AboutUs from "../../components/about-us-component/AboutUs";
import Features from "../../components/features-component/Features";
import ImageSection from "../../components/image-section-component/ImageSection";
import Home from '../../components/home-component/Home'
import Demo from '../../components/demo-component/Demo'
import Footer from '../../components/footer-component/Footer'
import {History} from 'history';
import "scroll-behavior-polyfill";
import OurAppsSection from "../../components/our-apps-section-component/OurAppsSection";
import PricingSection from "../../components/pricing-section-component/PricingSection";

interface Props {
    history: History,
    location: Location,
}

interface StateType {
    playing: boolean;
    headerVersion: string;
    hasControls: boolean;
}

export default class HomePage extends Component<Props, StateType> {
    features?: React.RefObject<HTMLInputElement>;
    about?: React.RefObject<HTMLInputElement>;
    rapidSection?: React.RefObject<HTMLInputElement>;
    video?: any

    constructor(props: any) {
        super(props);
        this.state = {
            playing: false,
            hasControls: false,
            headerVersion: 'dark'
        }
        this.features = React.createRef();
        this.about = React.createRef();
        this.video = React.createRef();
        this.rapidSection = React.createRef();
        this.playVideo = this.playVideo.bind(this);
        this.stopVideo = this.stopVideo.bind(this);
        this.switchHeader = this.switchHeader.bind(this)
    }

    componentDidMount() {
        window.scroll(0, 0)
        const {history} = {...this.props}
        if (history.location.state) {
            if (history.location.state === 'features') this.scrollToSection(this.features)
            else if (history.location.state === 'about') this.scrollToSection(this.about)
        }
        if (history.location.search) {
            window.scroll(0, 0)
        }
        window.addEventListener('scroll', this.switchHeader)
        window.addEventListener('scroll', this.stopVideo)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.stopVideo)

    }

    scrollToSection(section: any) {
        let topOfElement = section.current.offsetTop - 80;
        window.scroll({top: topOfElement, behavior: "smooth"});
    }

    playVideo(video: any) {
        let topOfElement = video.current.offsetTop - 80;
        let vid = document.querySelector('video');
        if (vid && topOfElement) {
            vid.currentTime = 0;
            window.scroll({top: topOfElement, behavior: "smooth"});
            vid.play().then(r => console.log(''));
            this.setState({playing: true, hasControls: true})
        }
    }

    stopVideo(video: any) {
        let v = this.video.current.getBoundingClientRect();
        let vid = document.querySelector('video');
        if (vid && v && this.state.playing) {
            let dist = window.pageYOffset + v.top;
            setTimeout(() => {
                if (vid && ((v.top + (v.height / 2) <= 0) || (dist - v.height > window.pageYOffset))) {
                    this.setState({playing: false, hasControls: false})
                    vid.pause()
                }
            }, 1500)
        } else {
            let dist = window.pageYOffset + v.top;
            if (vid && ((v.top + (v.height / 2) <= 0) || (dist - v.height > window.pageYOffset))) {
                vid.pause()
            }
        }
    }

    switchHeader() {
        let sectionTop = this.rapidSection?.current?.offsetTop;
        if (sectionTop && window.scrollY > sectionTop) {
            this.setState({headerVersion: 'light'})
        } else {
            this.setState({headerVersion: 'dark'})
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="home-page">
                    <Header areButtonsAvailable={true} switchHeader={this.switchHeader}
                            headerVersion={this.state.headerVersion}/>
                    <Home playing={this.state.playing} hasControls={this.state.hasControls} playVideo={this.playVideo}
                          video={this.video}/>
                    <div ref={this.rapidSection}>
                        <IsRapidDonkeyForYou/>
                    </div>
                    <OurAppsSection/>
                    <div ref={this.features}>
                        <Features align="features"/>
                    </div>
                    <ImageSection/>
                    <PricingSection/>
                    <div ref={this.video}>
                        <Demo/>
                    </div>
                    <div ref={this.about}>
                        <AboutUs align="about"/>
                    </div>
                    <Footer/>
                </div>
            </React.Fragment>
        );
    }
}

import React from 'react'
import {Link} from 'react-router-dom'
import './Header.scss'
import logolight from '../../../assets/images/Logo-light.svg'
import logodark from '../../../assets/images/Logo-dark.svg'

interface AppProps {
    areButtonsAvailable?: boolean;
    switchHeader?: any;
    headerVersion?: any;
    activeLink?: string;
}

interface HeaderState {
    isOpened: boolean,
}

export default class Header extends React.Component<AppProps, HeaderState> {
    wrapperRef: any;

    constructor(props: any) {
        super(props);
        this.state = {
            isOpened: false,
        }
        this.wrapperRef = React.createRef()
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    handleClickOutside(evt: any) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(evt.target)) {
            this.setState({isOpened: false})
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    render() {
        return (
            <div ref={this.wrapperRef}>

                <div className={`header ${this.props.headerVersion === 'dark' ? 'dark' : 'light'}`}>

                    <div className="header-info">
                        <div className='header-item'>
                            <a href="/">
                                <img src={this.props.headerVersion === 'dark' ? logolight : logodark}
                                     alt={'Rapidonkey logo'}/>
                            </a>
                        </div>
                    </div>
                    {this.props.areButtonsAvailable &&
                        <React.Fragment>
                            <div className='nav-bar'>
                                <Link to='/join-us'>
                                    <div className={`${this.props.activeLink?.includes("careers")? "active-nav-link" : "nav-link"}`}>Careers</div>
                                </Link>
                                <Link to='/articles'>
                                    <div className={`${this.props.activeLink?.includes("articles")? "active-nav-link" : "nav-link"}`}>Articles</div>
                                </Link>
                            </div>
                            <div className='hamburger-menu'>
                                <img
                                    className={` ${this.state.isOpened ? 'opened' : 'closed'} ${this.props.headerVersion === 'dark' ? 'dark' : 'light'}`}
                                    onClick={() => this.setState({isOpened: !this.state.isOpened})}
                                    alt={'Expand hamburger menu'}/>
                                <div
                                    className={`mobile-menu ${this.state.isOpened ? 'display' : ''} ${this.props.headerVersion === 'dark' ? 'dark' : 'light'}`}>
                                    <Link to='/join-us'>
                                        <div className='nav-link'>Careers</div>
                                    </Link>
                                    <Link to='/articles'>
                                        <div className='nav-link'>Articles</div>
                                    </Link>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>
        )
    }
}

export class Contact {
	firstName:string
	lastName:string
	email:string
	phone:string
	companyName:string
	companyWebsite:string
	deliveries:string
	agreedTerms:boolean;

	constructor(data: any) {
		this.firstName = data?.firstName
		this.lastName = data?.lastName
		this.email = data?.email
		this.phone = data?.phone
		this.companyName = data?.companyName
		this.companyWebsite = data?.companyWebsite
		this.deliveries = data?.deliveries
		this.agreedTerms = data?.agreedTerms
	}
}

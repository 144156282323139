export class Pricing {
    imagePath: string;
    title: string;
    subTitle: string;
    price: number;
    features: [string];

    constructor(data: any) {
        this.imagePath = data?.imagePath
        this.title = data?.title
        this.subTitle = data?.subTitle
        this.price = data?.price
        this.features = data?.features
    }
}
import React from 'react';
import './Demo.scss'
import {Link} from 'react-router-dom'

export default class Demo extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className='demo section'>
                    <span className='section-text'>Wanna see how our solution can <span
                        className=' weight-bold'>help</span> your business?</span>
                    <Link to='/contact'>
                        <div className='button blue medium default-hover'> Contact us</div>
                    </Link>
                </div>
            </React.Fragment>
        )
    }
}
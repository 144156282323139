import React, {useContext, useState, useEffect, createContext} from "react";

interface ApiContext {
    positions: any
}

const APIContext = createContext<ApiContext>({} as ApiContext);

export function APIContextProvider(children: any) {
    const [positions, setPosition] = useState([]);
    useEffect(() => {
        async function fetchData() {
            const resp = await fetch(`${process.env.REACT_APP_RAPID_DONKEY_WEBSITE_API}/job`); 
            const jsonJobs = await resp.json();
            const data = (jsonJobs.jobData);
            setPosition(data);
        }
        fetchData().then(r => console.log(''));
    }, []);

    return (
        <APIContext.Provider
            value={{
                positions
            }}
        >{children.children}
        </APIContext.Provider>
    );
}

export function useAPI() {
    const context = useContext(APIContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
}

export class jobData {
    title: string;
    city: string;
    section_title_1: string;
    section_text_1: string;
    section_title_2: string;
    section_text_2: string;
    to_do: string;
    section_title_3: string;
    requirements: any;
    id: any;
    hasLinks:string;

    constructor(data: any) {
        this.title = data?.title
        this.city = data?.city
        this.section_text_1 = data?.section_text_1
        this.section_title_1 = data?.section_title_1
        this.section_text_2 = data?.section_text_2
        this.section_title_2 = data?.section_title_2
        this.section_title_3 = data?.section_title_3
        this.to_do=data?.to_do
        this.requirements=data?.requirements
        this.id=data?.id;
        this.hasLinks=data?.hasLinks;
    }
}

import React, {Component} from "react";
import icon from '../../../assets/images/icon.svg'
import remove from '../../../assets/images/remove-x.svg'
import "./Dropzone.scss";

interface Props {
	disabled: any,
	onChange: any,
	name: any,
	onRemove: any,
	error: boolean,
}

interface State {
	highlight: boolean;
	added: boolean;
	fileName: string
}

export default class Dropzone extends React.Component<Props, State> {
	fileInputRef?: React.RefObject<HTMLInputElement>;

	constructor(props: any) {
		super(props);
		this.state = {
			highlight: false,
			added: false,
			fileName: ''
		};

		this.fileInputRef = React.createRef();

		this.openFileDialog = this.openFileDialog.bind(this);
		this.onFilesAdded = this.onFilesAdded.bind(this);
		this.onDragOver = this.onDragOver.bind(this);
		this.onDragLeave = this.onDragLeave.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.onFileRemove = this.onFileRemove.bind(this)
	}

	openFileDialog() {
		if (this.state.added && this.state.fileName) return;
		if (this.props.disabled) return;
		this.fileInputRef?.current?.click();
	}

	onFilesAdded(evt: any) {
		if (this.props.disabled) return;
		const files = evt.target.files;
		this.props.onChange(files[0], this.props.name)
		this.setState({added: true, fileName: files[0].name})
	}

	onFileRemove(evt: any) {
		if (this.props.disabled) return;
		this.props.onRemove(this.props.name)
		this.setState({added: false, fileName: ''})
	}


	onDragOver(evt: any) {
		evt.preventDefault();
		if (this.props.disabled) return;
		this.setState({highlight: true});
	}

	onDragLeave() {
		this.setState({highlight: false});
	}

	onDrop(event: any) {
		event.preventDefault();
		if (this.props.disabled) return;
		const files = event.dataTransfer.files;
		this.props.onChange(files[0], this.props.name)
		this.setState({highlight: false, added: true, fileName: files[0].name});
	}

	render() {
		return (
			<React.Fragment>
				<div
					className={`custom-dropzone ${this.state.highlight ? "Highlight" : ""}
                    ${this.props.error?'drop-error':''}`}
					onDragOver={this.onDragOver}
					onDragLeave={this.onDragLeave}
					onDrop={this.onDrop}
					onClick={this.openFileDialog}
					style={{cursor: this.props.disabled ? "default" : "pointer"}}
				>
					<input
						ref={this.fileInputRef}
						className="file-input"
						type="file"
						onChange={this.onFilesAdded}
					/>
					<div className={`drop-container ${this.state.added ? 'hide' : 'drop-container flex'}`}>
						<img
							alt="upload"
							className="drop-icon"
							src={icon}
						/>
						<div className='grey-text desktop'>Drag and drop or < span className='weight-bold'> browse </span> your
							file
						</div>
						<div className='grey-text mobi'>Upload your file
						</div>
					</div>

					{this.state.added && this.state.fileName &&
                    <div className='file-name-container'>
                        <div>{this.state.fileName}</div>
                        <div onClick={this.onFileRemove} className='remove-file'>
                            <img src={remove} alt='remove-file'/>
                        </div>
                    </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}


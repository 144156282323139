export class Article {
    id: string;
    category: string;
    image: string;
    title: string;
    subtitles: [string];
    contents: [string];

    constructor(data: any) {
        this.id = data?.id
        this.category = data?.category
        this.image = data?.image
        this.title = data?.title
        this.subtitles = data?.subtitles
        this.contents = data?.contents
    }
}
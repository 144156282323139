import React from "react";
import {useAPI} from "./apiContext";
import loader from '../../assets/images/Rapidonkey_Loader.gif'

interface JobProps {
    id: any;
    jobHasLink: any;
    hasAdditionalLink: any;
}

export default function Jobs(props: JobProps) {
    const {positions} = useAPI();
    const jobId = (parseInt(props.id)-1);
    if (positions[jobId] && positions[jobId].hasLinks && !props.hasAdditionalLink) {
        props.jobHasLink(true)
    }
    return (
        <React.Fragment>
            {typeof positions[jobId] === 'undefined' ?
                <div className='loading-container'>
                    <img className='loader' src={loader}/>
                </div> :
                <React.Fragment>
                    <span className='section-title big-size'> {positions[jobId].title} </span>
                    <span className='section-info'> {positions[jobId].city} </span>
                    <hr/>
                    <div className='job-info'>
                        <div className='info-title'> {positions[jobId].section_title_1}</div>
                        <div>
                            {(positions[jobId].section_text_1 && positions[jobId].section_text_1.split('/').map((paragraph: any) =>
                                <p className='grey-text '>{paragraph}</p>
                            ))}
                        </div>

                        <div className='info-title'>{positions[jobId].section_title_2}</div>
                        <div>
                            {(positions[jobId].section_text_2 && positions[jobId].section_text_2.split('/').map((paragraph: any) =>
                                <p className='grey-text '>{paragraph}</p>
                            ))}
                        </div>                        <ul>
                            {(positions[jobId].to_do && positions[jobId].to_do.split('/').map((todo: any) =>
                                <li>{todo}</li>
                            ))}
                        </ul>
                        <div className='info-title'> Requirements</div>
                        <ul>
                            {positions[jobId].requirements && positions[jobId].requirements.split('/').map((requirements: any) =>
                                <li>{requirements}</li>
                            )}
                        </ul>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    );
}

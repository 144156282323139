import React, {useEffect, useState} from 'react'
import './Articles.scss'
import Header from "../../components/header-component/Header";
import Footer from "../../components/footer-component/Footer";
import {Article} from "../../models/article";
import {request, gql} from 'graphql-request';
import ArticlesContainer from "../../components/articles-component/ArticlesContainer";

function Articles() {
    const [articles, setArticles]: [Article[], any] = useState([]);

    function getArticles() {
        const endpoint: string = (process.env.REACT_APP_HYGRAPH_URL as string);
        const query = gql`{
                articles {
                    id
                    title
                    image {
                      url
                    }
                }
            }`
        request({
            url: endpoint,
            document: query,
            variables: null,
        }).then((data) => {
            setArticles(data.articles.map((el: any) => new Article({
                id: el.id,
                // category: el.category,
                title: el.title,
                image: el.image.url,
            })))
        })
    }

    useEffect(() => {
        getArticles()
    }, [])

    return (
        <>
            <Header areButtonsAvailable={true} activeLink={"articles"}/>
            <div className={"section"}>
                <span className='section-title big-size article-section-title '>Articles</span>
                <ArticlesContainer articles={articles}/>
            </div>
            <Footer/>
        </>
    )
}

export default Articles
import React from "react";
import './Home.scss';
import ReactPlayer from "react-player";
import playBtn from "../../../assets/images/Play button.svg";

interface CarouselState {
    playButton: boolean,
    height: any,
}

interface Props {
    video?: any
    playVideo?: any
    playing?: boolean
    hasControls: boolean;
}

export default class Home extends React.Component<Props, CarouselState> {

    constructor(props: any) {
        super(props)

        this.state = {
            playButton: true,
            height: '80%'
        }
        this.playVideo = this.playVideo.bind(this);
        this.checkScreenSize = this.checkScreenSize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.checkScreenSize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkScreenSize)
    }

    checkScreenSize() {
        if (window.innerWidth < 1024) {
            this.setState({height: '100%'})
        } else {
            this.setState({height: '80%'})
        }
    }

    playVideo() {
        let video = document.querySelector('video');
        if (video) {
            video.play();
            video.setAttribute("controls", "true")
            this.setState({playButton: false})
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className='home section'>
                    <div className='hero'></div>
                    <span className='big-title'> Logistics for local businesses </span>
                    <span className='big-text'> Our software empowers your delivery</span>
                    <div className='player-container'>
                        <ReactPlayer
                            className='player'
                            controls={this.props.hasControls}
                            playing={this.props.playing}
                            width={this.state.height}
                            height={this.state.height}
                            url="videos/Rapidonkey Presentation.mp4"
                        />
                        <a className={(!this.state.playButton || this.props.playing) ? `hide ` : ``}
                           id='playButton'
                           onClick={this.playVideo}
                        ><img src={playBtn} alt={"Play button"}/></a>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}